<template>
    <div>
        <v-expand-transition>
            <v-card max-width="500" v-show="expand" color="#fff" class="pa-3 my-5 fixLeft shadow">
                <v-card-title>
                聯繫我們
                <v-icon class="ml-auto" @click="expand=false">mdi-close</v-icon>
                </v-card-title>
                <v-card-text class="px-4 pt-1">
                <v-row>
                    <v-col class="flex-grow-0 text-center pa-2 pt-4">
                    <qrcode-vue value="https://line.me/R/ti/p/@585cvzsv" size="100" level="H"></qrcode-vue>
                    <a href="https://line.me/R/ti/p/@585cvzsv" target="_blank" class="nowrap mt-2 font-weight-bold" style="text-decoration:underline; display:block;">加入荔枝官方帳號</a>
                    </v-col>
                    <v-col class="flex-grow-1 pt-2" style="line-height:32px;">
                    需要幫助嗎？可點擊左方連結或掃QRcode加入荔枝官方帳號，透過一對一聊天留言您遇到的問題或是寄信到客服信箱，我們會儘快為您服務！
                    <br/>
                    <!-- 請點擊左方連結或掃QRcode加入，透過一對一聊天取得客服。<br/> -->
                    客服信箱：support@lychee.tw
                    </v-col>
                </v-row>
                </v-card-text>
            </v-card>
        </v-expand-transition>
        <transition name="fadeDelay">
        <v-btn rounded class="fixLeft" @click="expand=true" v-show="!expand" outlined color="primary" style="background:#fff;">
            <v-icon size="20" class="mt-1 mr-2">mdi-help-circle-outline</v-icon>幫助
        </v-btn>
        </transition>
    </div>
</template>
<script>
export default {
    components: { QrcodeVue },
    data(){
        return{
            expand: false
        }
    }
}
</script>
<style scoped lang="scss">
.fixLeft{
    /* display: block; */
    position: fixed;
    bottom: 20px;
    right: 20px;
    &.shadow{
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px !important;
    }
}
.fadeDelay-enter-active,.fadeDelay-leave-active{
    transition: opacity 2s;
}
.fadeDelay-enter,.fadeDelay-leave-to{
    opacity: 0;
}
.fadeDelay-enter-to,.fadeDelay-leave{
    opacity: 1;
}

</style>
