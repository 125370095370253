<template>
  <v-app>
    <v-app-bar app color="primary" dark class="appBar">
      <h2 v-if="mobileView" class="indexText nowrap" style="font-size:20px;">荔枝Mini</h2>
      <h2 v-else class="indexText nowrap">荔枝Mini</h2>
      <span class="subtitle pt-md-3 ml-3 pl-3 nowrap">MGM增粉模組</span>
      <v-spacer></v-spacer>
      <!-- toolbar -->
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn 
          v-if="user && Object.keys(user).length === 0 && user.constructor === Object"
          @click="getLoginUrl"
          color="white" dark text disabled v-bind="attrs"
          >
            <!-- <span>登入</span>  -->
          </v-btn>
          <v-btn v-else color="white" dark outlined v-bind="attrs" v-on="on" class="px-4">
            <span style="text-transform:none;letter-spacing:normal;font-weight:500;">{{ user.first_name +' '+user.last_name }}</span>
          </v-btn>
        </template>
        <v-list>
          <!-- <v-list-item @click="switchAcc">
            <v-list-item-title>切換邀請帳號</v-list-item-title>
          </v-list-item> -->
          <v-list-item @click="logout">
            <v-list-item-title>登出</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
    <loading></loading>

    <!-- sidebar -->
    <v-navigation-drawer
        v-if="loginCookie && $router.history.current.path != '/' && $router.history.current.path != '/login' &&  !showBots"
        permanent
        app
        :expand-on-hover="mobileView"
      >
      <v-row class="flex-column" style="height:100%;">
        <v-col class="flex-grow-1 flex-shrink-0">
          <v-list>
            <v-list-item style="height:64px;"></v-list-item>
            <!-- Accouont -->
            <v-list-item>
              <v-list-item-icon>
                <v-avatar v-if="activeBot.picture_url!=null" :size="mobileView?28:50">
                  <img
                    :src="activeBot.picture_url"
                    :alt="activeBot.basic_id"
                  >
                </v-avatar>
                <v-icon v-else>mdi-account</v-icon>
              </v-list-item-icon>
              <v-list-item-title class="accTitle"> 
                <div class="acc">
                  <span class="font-weight-bold mr-3 my-2">{{ botDisplayName }}</span> 
                  <span @click="switchAcc" class="switchAccBtn">切換帳號</span>
                </div>
                <p class="mb-0"><span style="display:inline-block;" class="mb-1">使用期限
                  </span><br/>{{activeBot.end_at | moment}}</p>
              </v-list-item-title>
            </v-list-item>
            <v-list-item v-if="mobileView && expiredAfter7">
              <v-list-item-icon>
                  <v-icon color="amber darken-3" size="22" style="margin:4px 0 0 1px;">mdi-alert-circle-outline</v-icon>
              </v-list-item-icon>
                <v-list-item-title class="font-weight-bold mt-4" style="font-size:12px;">
                  <v-alert 
                  dense
                  color="amber darken-3"
                  text
                  dark
                  class="mb-0 text-center"
                  style="width:100%;font-size:12px;">
                    <span>帳號將於{{lastDays}}日內到期</span>
                    <div class="pay" @click="goPay">前往付費</div>
                  </v-alert>
                </v-list-item-title>
            </v-list-item>
            <v-list-item v-if="!mobileView && expiredAfter7" class="mt-2">
              <v-alert
                dense
                border="left"
                color="amber darken-3"
                text
                dark
                class="mx-auto"
                style="width:100%;font-size:12px;"
              >
                <strong>
                  <v-icon color="amber darken-3" size="20" class="mr-1 mb-1">mdi-alert-circle-outline</v-icon> 
                  帳號將於{{lastDays}}日內到期</strong> 
                <div class="pay" @click="goPay">前往付費</div>
                
              </v-alert>
            </v-list-item>
          </v-list>

          <v-divider></v-divider>
          <!-- Content -->
          <v-list nav dense>
            <v-list-item link v-for="item in items" :key="item.page" @click="goPage(item.page)">
              <v-list-item-icon>
                <v-icon :color="item.page ==activePage?'primary':''">{{ item.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-title :class="item.page ==activePage?['primary--text','font-weight-bold']:''">{{ item.title }}</v-list-item-title>
            </v-list-item>
            <v-list-item link @click="downloadFile">
              <v-list-item-icon>
                <v-icon>mdi-book-variant</v-icon>
              </v-list-item-icon>
              <v-list-item-title>操作手冊下載</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-col>
        <v-divider></v-divider>
        <!-- Links -->
        <v-col class="flex-grow-0 flex-shrink-1 pt-0">
          <v-list nav dense>
            <!-- <v-list-item link v-if="mobileView" @click="downloadFile">
              <v-list-item-icon>
                  <v-icon size="22" style="margin:4px 0 0 1px;">mdi-book-variant</v-icon>
              </v-list-item-icon>
                <v-list-item-title class="font-weight-bold mt-1" >
                  <span class="grey--text text--darken-1">操作手冊下載</span>
                </v-list-item-title>
            </v-list-item>

            <v-list-item v-else link class="my-3">
              <v-list-item-title class="font-weight-bold mt-1 text-center">
                <v-btn color="primary" class="mb-1 px-12"  @click="downloadFile">
                  <span style="font-size:14px;">
                    操作手冊下載
                  </span>
                  <v-icon size="20" class="ml-2">mdi-book-outline</v-icon></v-btn>
              </v-list-item-title>
            </v-list-item> -->

            <a href="https://lychee.tw/nice-push.html" target="_blank">
              <v-list-item link>
                <v-list-item-icon>
                  <img class="ml-1" src="img/logo_5050.jpg
                  "/>
                </v-list-item-icon>
                <v-list-item-title class="font-weight-bold mt-1">
                  <span class="grey--text text--darken-1">荔枝好推官網</span>
                  <v-icon small class="ml-2 mb-1">mdi-open-in-new</v-icon>
                </v-list-item-title>
              </v-list-item>
            </a>

            <a :href="`https://manager.line.biz/account/${activeBot.basic_id}`" target="_blank">
              <v-list-item link>
                <v-list-item-icon>
                  <div style="width:20px;overflow:hidden;margin:3px 0 0 2px;">
                    <img src="https://static.line-scdn.net/biz-app/edge/manager/img/common/favicon.ico
                  " style="object-fit:contain; width:100%;"/>
                  </div>
                  
                </v-list-item-icon>
                <v-list-item-title class="font-weight-bold mt-1">
                  <span class="grey--text text--darken-1">前往 LINE 官方後台</span>
                  <v-icon small class="ml-2 mb-1">mdi-open-in-new</v-icon>
                </v-list-item-title>
              </v-list-item>
            </a> 
          </v-list>
        </v-col>
      </v-row>
        
    </v-navigation-drawer>

    <v-main>
      <router-view/>
      <helper/>
    </v-main>
  </v-app>
</template>

<script>

axios.defaults.withCredentials = true;
axios.defaults.xsrfCookieName = 'csrftoken';
axios.defaults.xsrfHeaderName = 'X-CSRFToken';

import { mapGetters,mapActions } from 'vuex'
import Loading from './components/Loading.vue'
import Helper from './components/Helper.vue'
import moment from 'moment'

export default {
  name: 'App',
  components:{ Loading,moment,Helper },
  data(){
    return{
      // tab:0,
      items: [
          { page: '/invit', title: '我的邀請活動', icon: 'mdi-account-multiple-plus' },
          { page: '/ticket', title: 'LINE Point 點數管理', icon: 'mdi-star' },
          { page: '/rank', title: '邀請排行榜', icon: 'mdi-chart-box' },
      ],
      botDisplayName: '',
      lastDays: null,
    }
  },
  computed:{
    ...mapGetters(['user','loginCookie','showBots','bot']),
    invBread(){
      return JSON.parse(localStorage.getItem('invBread'))
    },
    mobileView(){
      let breakPoint = this.$vuetify.breakpoint.name
      if(breakPoint == 'sm' || breakPoint == 'xs'){
        return true
      }
    },
    activeBot(){
      let activeBot = JSON.parse(localStorage.getItem('activeBot'))
      if(this.bot.id){
        return this.bot
      }else{
        return activeBot
      }
    },
    activePage(){
      return this.$route.fullPath
    },
    expiredAfter7(){
      let d = new Date()
      // d.setDate(d.getDate()+10)
      let endDay = new Date(this.activeBot.end_at)
      // console.log('d',d);
      // console.log('endDay',endDay);
      // console.log('days',new Date(endDay-d).getDate()-1);

      let lastD = new Date(endDay-d).getDate()
      console.log('lastD',lastD);
      if(lastD <= 7)this.lastDays = lastD

      let after7d = new Date(d.setDate(d.getDate()+ 7))
      if(after7d > endDay) return true
      else return false
    },
  },
  filters: {
    moment: function (date) {
        return moment(date).format('YYYY-MM-DD HH:mm:ss');
    }
  },
  async created(){
    await this.checkLogin();

    // event: lychee_mini_register/login
    this.$gtag.event('conversion', {'send_to': 'AW-971348670/f871CJzghIkDEL61ls8D'});
    try {
        fbq('track', 'StartTrial');
    } catch (error) {
        console.log(error)
    }
  },
  mounted(){
    this.$gtag.config('AW-971348670');
    this.$gtag.linker({
            'accept_incoming': true
    });

    this.$gtag.event('conversion', {'send_to': 'AW-971348670/ldEaCOTspe8CEL61ls8D'});

    if(this.invBread.length>2){
      this.botDisplayName = this.invBread[1].text
    }
    let showBots = JSON.parse(localStorage.getItem('showBots'))
    if(showBots != null){
        console.log('showBots',showBots);
        if(showBots){
          this.$store.commit('setInvitations' , [])
        }
        this.$store.commit('setShowBots',showBots)
    }
  },
  watch:{
    // tab(val){
    //   switch (val){
    //     case 0:
    //       this.goPage('/invit')
    //       break
    //     case 1:
    //       this.goPage('/ticket')
    //       break
    //     case 2:
    //       this.goPage('/rank')
    //       break
    //   }
    // },
    bot(val){
      console.log('botbot',val);
      if(val.display_name){
        this.botDisplayName = val.display_name
      }else if (!val.display_name && this.invBread.length>2){
        this.botDisplayName = this.invBread[1].text
      }
    },
  },
  methods:{
    ...mapActions(['getLoginUrl', 'checkLogin', 'logout']),
    moment(date) {
      let a = moment(date).tz("Asia/Taipei");
      return a;
    },
    goPage(path){
      if(this.$router.history.current.path != path)
      this.$router.push(path)
    },
    switchAcc(){
      this.goPage('/invit')
      this.$store.commit('setShowBots',true)
      localStorage.setItem('showBots', true)
      localStorage.setItem('invBread', JSON.stringify(this.invBread.slice(0,1)))
    },
    downloadFile(){
      const link = document.createElement('a')
      link.href = '/MGM_V22.04.01.pdf'
      link.download = 'MGM_V22.04.01.pdf'
      link.dispatchEvent(new MouseEvent('click'))

    },
    goPay(){
      window.open('https://core.newebpay.com/EPG/lychee/F4Hh8O','_blank').focus()
    }
  }
};
</script>
<style lang="scss">
@import "assets/scss/main.scss";
</style>
<style scoped lang="scss">
.appBar{
  left:0 !important;
  z-index:99 !important;
  .subtitle{
    font-weight: 500;
    letter-spacing: 1px;
    border-left: 1px solid #fffa;
    font-size: 18px;
  }
}
.indexText{
  // font-family: 'Roboto';
  font-size: 32px;
  font-weight: normal;
  font-weight: 500;
}
.switchAccBtn{
  font-size:12px;
  color:#666;
  text-decoration:underline;
  cursor: pointer;
  display: inline-block;
  margin-bottom: 8px;
}
.accTitle{
  width:100%;
  .acc{
    display: flex;
    align-items: baseline;
    flex-wrap: wrap;
  }
  span{
    white-space: normal !important;
  }
  p{
    font-size: 12px;
    color: #aaa;
  }
}
.pay{
  border: 1px solid #ff8f00 ;
  border-radius: 20px;
  background-color: #ff8f00;
  padding: 8px;
  margin: 8px 0;
  color: #fff;
  text-align: center;
  font-weight: bold;
  font-size: 12px;
  letter-spacing: 1px;
  cursor: pointer;
}
</style>