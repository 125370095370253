<template>
    <div>
        <p class="mb-6 font-weight-bold text-h6">LINE Marketplace 使用注意事項</p>
        <div class="stepContent_text mb-8 px-md-10">
            <p>如果您同時使用 Messaging API，則市集上提供的外掛模組可能無法正常運行。</p>
            <p>使用外掛模組時，請注意不要同時使用 Messaging API。</p>
            <p>對於 Messagaing API 設置，請確保您具有以下狀態之一：(只需符合一項即可)</p>
            <p>(1)<span>LINE 官方帳號管理後台 > 設定 > Messaging API</span>  狀態未使用</p>
            <p>(2)<span>LINE 官方帳號管理後台 > 設定 > 回應設定 > 進階設定</span> 的 Webhook 項目必須為停用</p>
            <p>(3)<span>LINE 官方帳號管理後台 > 設定 > 回應設定 > 基本設定</span> 的回應模式設置必須為聊天模式</p>
            <p>一次只能使用市集上提供的一個外掛模組。</p>
            <p>請注意不要訂閱多個外掛模組。</p>
        </div>
        <v-checkbox :value="agree" @click="$emit('toggleCheck')" class="mb-8 px-md-10">
            <template v-slot:label>
                <div>
                已閱讀並同意
                <a href="https://lychee.tw/policy/157-user-term-of-nice-push.html" target="_blank" @click.stop>服務條款</a>
                及
                <a href="https://lychee.tw/policy/158-privacy-policy-of-nice-push.html" target="_blank" @click.stop>隱私權政策</a>
                </div>
            </template>
        </v-checkbox>
    </div>
</template>
<script>
export default {
    props:['agree'],
    data(){
        return{
            
        }
    }
}
</script>
<style lang="scss" scoped>
.stepContent{
    margin: 60px auto;
    min-height: 30vh;
    text-align: center;
    display: flex;
    justify-content: center;
    &_bot{
        text-align: center;
    }
    &_text{
        text-align: left;
        p{
        margin-bottom: 0;
        line-height: 32px;
        }
        span{
        color: #74b36b;
        }
    }
}

</style>