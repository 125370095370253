<template>
    <v-dialog
    v-model="createDialog"
    persistent
    no-click-animation
    fullscreen
    transition="dialog-bottom-transition"
    style="position:relative;"
    >
    <loading></loading>
    <v-card class="pt-10" color="#fafafa" min-width="320px">
    <v-container style="max-width:1000px;">
        <v-card-title class="pt-2 pb-3">
            <span class="text-h4">{{editmode ? '編輯' : '建立'}}邀請活動</span>
        </v-card-title>
        <div v-if="editmode" class="cardShadow">
            <v-card-text class="px-6 py-5 d-flex">
                <div class="title mr-3">活動狀態</div>
                <v-switch v-model="isActive" @change="changed=true"
                :label="isActive ? '開啟' : '關閉'" hide-details class="mt-0" style="max-width:100px;"></v-switch>
            </v-card-text>
        </div>
        <div class="cardShadow">
            <v-card-text class="px-6 py-5">
            <div class="title mb-3">基本資訊</div>
            <v-form ref="form">
                <v-text-field v-model.trim="title" @change="changed=true" outlined :rules="nameRules" label="邀請活動名稱*"></v-text-field>
                <v-row>
                    <v-col cols="12" md="6">
                        <v-textarea v-model="guide_text" @change="changed=true" hide-details outlined label="邀請引導文字" placeholder="e.g. 邀請 5 位好友，可獲得 LINE Point 點數 5 點！！"></v-textarea>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-textarea v-model="shared_text" @change="changed=true" outlined label="分享內容" placeholder="e.g. 邀請你加入 XXX 官方帳號，加入後即可獲得 LINE Point 點數 5 點！！" counter maxlength="85"></v-textarea>
                    </v-col>
                </v-row>
                <v-row class="no-gutters" align="center">
                    <div class="preText nowrap mr-1" :class="{'red--text':timeEmpty}">邀請活動期間*</div>
                    <v-col md="3" cols="12">
                        <div class="d-flex flex-column pt-3">
                        <date-picker prefix-class="mx" range v-model="time" type="datetime" 
                        @change="changed=true;"></date-picker>
                        <small v-if="timeEmpty" class="red--text">必填</small>
                    </div>
                    </v-col>
                    <v-spacer></v-spacer>
                </v-row>
            </v-form>
            </v-card-text>
        </div>
        <div class="cardShadow">
            <v-card-text class="px-6 py-5">
                <div class="d-flex">
                    <div class="title mb-3 mr-3">邀請成功給予獎勵</div>
                    <v-switch v-model="isAward" @change="changed=true" :label="isAward ? '開啟' : '關閉'" hide-details class="mt-0"></v-switch>
                </div>
                <v-form ref='awardForm' v-if="isAward">
                <v-row>
                    <v-col cols="12" md="6">
                        <div class="body-1 mt-8 mb-5">達成條件</div>
                        <v-text-field v-model="invFriNum" @change="changed=true" :rules="friRules" dense outlined single-line class="friNum" type="number">
                        <template v-slot:prepend>
                            <div class="nowrap mt-1 preText">邀請成功好友數*</div> 
                        </template>
                        </v-text-field>
                    </v-col>
                    <!-- <v-col cols="12" md="6">
                        <div class="body-1 mt-8" >達成獎勵</div>
                        <v-select v-model="reward_old" @change="changed=true" :items="[5]" :rules="friRules" dense outlined single-line type="number" hint="邀請人條件達成可獲得的獎勵" persistent-hint
                    class="friNum mt-5">
                            <template v-slot:prepend>
                                <div class="nowrap mt-1 preText">LINE Points 點數*</div> 
                            </template>
                            <template v-slot:item="{item}">
                                <div class="nowrap mt-1 preText">{{item}} 
                                    <span class="ml-3">(剩餘{{lineP5}}張)</span>
                                </div> 
                            </template>
                        </v-select>
                    </v-col> -->
                    <!-- === -->
                    <v-col cols="12" md="6">
                        <div class="body-1 mt-8" >達成獎勵</div>
                        <v-select v-model="reward_old" @change="changed=true"
                        item-text="price" item-value="type" :items="rold_point_items" :rules="nameRules" dense outlined single-line type="number" hint="邀請人條件達成可獲得的獎勵" persistent-hint
                    class="friNum mt-5">
                            <template v-slot:prepend>
                                <div class="nowrap mt-1 preText">LINE Points 點數*</div> 
                            </template>
                            <template v-slot:item="{item}">
                                <div class="nowrap mt-1 preText">{{item.price}} 
                                    <span class="ml-3">(剩餘{{item.amount}}張)</span>
                                </div> 
                            </template>
                        </v-select>
                    </v-col>
                    <!-- === -->
                </v-row>
                <v-divider class="mt-8"></v-divider>
                <div class="body-1 mt-8" >新好友獎勵</div> 
                <v-row class="mt-2">
                    <!-- <v-col cols="12" md="6">
                        <v-select v-model="reward_new" @change="changed=true" :items="[0,5]" dense outlined single-line hint="新好友成功加入可獲得的獎勵" persistent-hint type="number"
                    class="friNum">
                            <template v-slot:prepend>
                                <div class="nowrap mt-1 preText">LINE Points 點數</div> 
                            </template>
                            <template v-slot:item="{item}">
                                <div class="nowrap mt-1 preText">{{item}} 
                                    <span v-show="item>0" class="ml-3">(剩餘{{lineP5}}張)</span>
                                </div> 
                            </template>
                        </v-select>
                    </v-col> -->
                    <!-- === -->
                    <v-col cols="12" md="6">
                        <v-select v-model="reward_new" @change="changed=true" :items="rnew_point_items" item-text="price" item-value="type" dense outlined single-line hint="新好友成功加入可獲得的獎勵" persistent-hint type="number"
                    class="friNum">
                            <template v-slot:prepend>
                                <div class="nowrap mt-1 preText">LINE Points 點數</div> 
                            </template>
                            <template v-slot:item="{item}">
                                <div class="nowrap mt-1 preText">{{item.price}} 
                                    <span v-show="item.price>0" class="ml-3">(剩餘{{item.amount}}張)</span>
                                </div> 
                            </template>
                        </v-select>
                    </v-col>
                    <!-- === -->
                    <v-spacer></v-spacer>
                </v-row>
                </v-form>
            </v-card-text>
        </div>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
            color="#333"
            outlined
            elevation="0"
            @click="alertOpen"
            >
            取消
            </v-btn>
            <v-btn
            color="primary"
            elevation="0"
            @click="onSubmit"
            >
            確認
            </v-btn>
        </v-card-actions>
    </v-container>
    </v-card>
    </v-dialog>
</template>
<script>
import Loading from './Loading.vue'
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/zh-cn';
// import QrcodeVue from 'qrcode.vue';
import { mapGetters,mapActions,mapMutations } from 'vuex'
export default {
    components: { DatePicker, Loading },
    data(){
        return {
            isActive: null,
            title: '',
            guide_text: '',
            shared_text: '',
            time: [],
            isAward: false,
            changed: false,
            invFriNum: null,
            reward_new: null,
            reward_old: null,
            rnew_point_items: [],
            rold_point_items: [],
            // ro_items:[{value:5, text:5'}],
            nameRules: [
                v => !!v|| '必填',
            ],
            friRules: [
                v => !!v || '必填',
                v => v > 0 || '好友數不得為0或負數',
            ],
            timeEmpty: false,
            formBorderColor: 'rgba(0, 0, 0, 0.4)',
        }
    },
    computed:{
        ...mapGetters(['createDialog','editmode','invit','cancelAns','lineP5','pointAmnt']),
    },
    watch :{
        editmode(val){
            console.log('edm',val);
            if(val){
                this.title = this.invit.title
                this.guide_text = this.invit.guide_text
                this.shared_text = this.invit.shared_text
                this.time = [new Date(this.invit.start_at),new Date(this.invit.end_at)]
                this.isActive = this.invit.is_active
                if(!!this.invit.trigger_num){
                    this.isAward = true
                    this.invFriNum = this.invit.trigger_num
                    // let rnew = this.invit.reward_new || '0'
                    // let rold = this.invit.reward_old || '0'
                    // this.reward_new = parseInt(rnew.slice(-1))
                    // this.reward_old = parseInt(rold.slice(-1))
                    this.reward_new = this.invit.reward_new
                    this.reward_old = this.invit.reward_old
                }
            }
        },
        async isAward(val){
            //todo:  check ticket and alert
            if(!val){
                this.invFriNum = null
                this.reward_new = null
                this.reward_old = null
                this.setLineP5(null)
                this.setPointAmnt([])
            }else{
                await this.getTicketSummary()
                console.log('this.lineP5',this.lineP5);
                // if(this.lineP5 == 0){
                if(this.pointAmnt.length == 0){
                    let msg="LINE Point點數不足無法給予獎勵，請洽荔枝客服儲值點數。<br/>點擊下方按鈕或掃描QRcode，加入荔枝好推透過一對一聊天與我們聯繫。"
                    this.setAlertMsg({mode:'lackLineP',msg})
                    this.setAlertDialog(true)
                    this.isAward = false
                }
                //set items
                this.rold_point_items = this.pointAmnt
                this.rnew_point_items = [{price:"0",amount:0,type:null},...this.pointAmnt]
            }
        },
        time(val){
            console.log('time change',val);
            if(this.changed){
                this.timeRules()
            }
        },
        timeEmpty(val){
            this.formBorderColor = val ?  'red' : 'rgba(0, 0, 0, 0.4)'
            this.setFromBorder()
        },
        cancelAns(val){
            if(val){
                this.clean()
            }
        },
    },
    mounted(){
        this.setFromBorder()
    },
    methods:{
        ...mapActions(['getInvitationList','toggleInvitation','createInvitation','updateInvitation','getTicketSummary']),
        ...mapMutations(['setAlertDialog','setAlertMsg','setCreateDialog','setEditmode','setCancelAns','setIsloading','setBgHeight','setLineP5','setPointAmnt']),
        setFromBorder(){
            document.documentElement.style.setProperty('--formBorderColor', this.formBorderColor);
        },
        timeRules(){
            if(this.time.length == 0 || this.time.includes(null)){
                this.timeEmpty = true
            }else{
                this.timeEmpty = false
            }
        },
        setLoadingBg(){
            var pageHeight = 0;

            function findHighestNode(nodesList) {
                for (var i = nodesList.length - 1; i >= 0; i--) {
                    if (nodesList[i].scrollHeight && nodesList[i].clientHeight) {
                        var elHeight = Math.max(nodesList[i].scrollHeight, nodesList[i].clientHeight);
                        pageHeight = Math.max(elHeight, pageHeight);
                    }
                    if (nodesList[i].childNodes.length) findHighestNode(nodesList[i].childNodes);
                }
            }

            findHighestNode(document.documentElement.childNodes);

            // The entire page height is found
            console.log('Page height is', pageHeight);
            this.setBgHeight(pageHeight+'px')
        },
        async onSubmit() {
            try {
                this.setLoadingBg()
                let check = this.check()
                if(!check) return
                this.setIsloading(true)
                let payload = this.createPayload()
                if(!this.editmode){
                    await this.createInvitation(payload)
                    this.$gtag.event('conversion', {'send_to': 'AW-971348670/JKizCLnNtYkDEL61ls8D'});
                    this.$gtag.event('create_event', {'event_category': "envet", 'event_label': "action"})
                }else{
                    await this.updateInvitation({id:this.invit.id , payload})
                    await this.toggleStatus()
                    this.$gtag.event('update_event', {'event_category': "envet", 'event_label': "action"})
                }
                await this.getInvitationList();
                this.clean()
            } catch (error) {
                console.log(error);
                throw new Error('Get bots Fail')
            }
        },
        check(){
            let pass = true
            let name = this.title
            if(!name || name == '' || this.time.length == 0){
                if((!name || name == '')){
                    this.$refs.form.validate()
                }
                if(this.time.length == 0 || this.time.includes(null)){
                    this.timeEmpty = true
                    this.formBorderColor = 'red'
                    this.setFromBorder()
                }
                pass = false
            }
            if(this.isAward){
                this.$refs.awardForm.validate()
                if(this.invFriNum <= 0 || this.reward_old == null) pass = false
            }
            console.log('pass',pass);
            return pass
        },
        createPayload(){
            // let rnew = `LINE_POINT_${this.reward_new}`
            // let rold = `LINE_POINT_${this.reward_old}`
            // if(this.reward_new == null || this.reward_new ==0){
            //     rnew = null
            // }
            // if(this.reward_old == null){
            //     rold = null
            // }
            let payload = {
                "title": this.title,
                "guide_text": this.guide_text,
                "shared_text": this.shared_text,
                "trigger_num": this.invFriNum,
                "reward_new": this.reward_new,
                "reward_old": this.reward_old,
                "start_at": this.time[0],
                "end_at": this.time[1],
                "is_active" : this.isActive
            }
            console.log('payload',payload);
            return payload
        },
        alertOpen(){
            console.log('change',this.changed);
            if(this.changed){
                this.setAlertDialog(true)
                let msg = `尚未儲存，確定要離開此頁面嗎？`
                this.setAlertMsg({mode:'leave',msg})
            }else{
                this.clean()
            }
        },
        clean(){
            this.changed = false
            this.isActive = null
            this.title = ''
            this.guide_text = ''
            this.shared_text = ''
            this.time = []
            this.invFriNum = null
            this.reward_new = null
            this.reward_old = null
            this.timeEmpty = false
            this.isAward = false
            this.formBorderColor = 'rgba(0, 0, 0, 0.4)'
            this.setFromBorder()
            this.setCreateDialog(false)
            this.setEditmode(false)
            this.setCancelAns(false)
            this.$refs.form.reset()
            this.setBgHeight('100vh')
        },
        async toggleStatus(){
            console.log(this.invit);
            if(this.isActive == this.invit.is_active && this.isActive == false) return
            
            let movement = ''
            if(this.isActive == this.invit.is_active && this.isActive == true){
                movement = 'activate'
            }else if(this.isActive != this.invit.is_active){
                movement = this.invit.is_active ? 'deactivate' : 'activate'
            }
            await this.toggleInvitation({id:this.invit.id, movement})
        },
    }
}
</script>
<style lang="scss" scoped>
    .cardShadow{
        background: #fff;
        box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
        margin-bottom: 16px;
        border-radius: 10px;
    }
    .preText{
        color: #555;
        width: 115px;
        font-size: 14px;
    }
</style>
<style lang="scss">
    $namespace: 'mx'; // change the 'mx' to 'xmx'. then <date-picker prefix-class="xmx" />
    $default-color: #555;
    $primary-color: #74b36b;
    $form-border-color: var(--formBorderColor);

    @import '~vue2-datepicker/scss/index.scss';
    .mx-input{
        height: 40px;
        border: 1px solid $form-border-color;
        &:hover{
            border-color: #000;
        }
        &:focus{
            border: 2px solid;
            border-color: $primary-color;
        }
        &-wrapper{
            max-width: 300px;
        }
    }
    @media (max-width: 321px ){
        .mx-input-wrapper{
            max-width: 245px;
        }
        .mx-datepicker-range{
            width: 245px !important;
        }
    }
    .friNum .v-text-field__slot input{
        text-align: right;
    }
    .friNum .v-text-field__slot label{
        left: auto !important;
        right: 16px !important;
    }
</style>
