<template>
<div>
  <div v-if="whitePage"></div>
  <v-container v-else class="wrap">
    <login-title class="mgmTitle"></login-title>
    <!-- <v-btn @click="setStep(1)">step 1</v-btn>
    <v-btn @click="setStep(2)">step 2</v-btn>
    <v-btn @click="setStep(3)">step 3</v-btn>
    <v-btn @click="setStep(4)">step 4</v-btn> -->
    <v-stepper v-model="step">
      <v-stepper-header>
        <v-stepper-step
          :complete="step > 1"
          step="1"
        >
          注意事項及條款
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step
          :complete="step > 2"
          step="2"
        >
          綁定 LINE 官方帳號
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step :complete="step > 3" step="3">
          選擇已綁定的帳號
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step step="4">
          設定成功，前往邀請
        </v-stepper-step>
        
      </v-stepper-header>
      

      <v-stepper-items>
        <v-stepper-content step="1" class="stepContent">
          <terms :agree="agree" @toggleCheck="agree=!agree"></terms>
          <v-btn
            color="primary"
            class="mb-5"
            @click="setStep(2)"
            :disabled="agree==false"
          >
            下一步
          </v-btn>
        </v-stepper-content>

        <v-stepper-content step="2" class="stepContent">
          <v-row align="center">
            <v-col class="text-left">
              <div class="pa-4">
                <h3 class="mb-5">與模組完成綁定後，會對我的官方帳號造成麼影響嗎?</h3>
                <p style="letter-spacing: 2px;line-height:2;">與荔枝Mini綁定後，官方帳號的設定不會有改變，僅有當好友在官方帳號內講出「選單」二字時，會叫出MGM活動選單。</p>
              </div>
            </v-col>
            <v-divider vertical></v-divider>
            <v-col>
              <v-card
                color="#fff"
                class="mb-5"
                elevation="0"
                max-height="200"
              >
              <div style="width:200px; margin:0 auto;">
                <img width="200" src="img/1.png" alt=""/>
              </div>
              </v-card>
              <p class="mb-8 font-weight-bold text-h5">綁定你想建立邀請活動的帳號</p>
              <v-btn
                color="primary"
                class="mb-5"
                @click="getModuleAuthUrl"
              >
                前往 LINE 綁定
              </v-btn>
            </v-col>
          </v-row>
          
        </v-stepper-content>

        <v-stepper-content step="3" class="stepContent_bot">
          <bot-cards :landing="true"></bot-cards>
          <p class="mb-8 font-weight-bold text-h5">請選擇已綁定的帳號</p>
          <v-btn
            color="primary"
            @click="clickChoose()"
            class="mb-5"
          >
            選好了
          </v-btn>
          <br/>
          <v-btn
            color="primary"
            class="my-5"
            text
            @click="getModuleAuthUrl"
          >
            綁定其他帳號
          </v-btn>
        </v-stepper-content>

        <v-stepper-content step="4" class="stepContent">
          <v-card
            color="#fff"
            class="mb-5"
            elevation="0"
            max-height="200"
          >
          <div style="width:200px; margin:0 auto;">
            <img width="200" src="img/2.png" alt=""/>
          </div>
          </v-card>
          <h2 class="mb-8"> 開始建立第一個邀請活動吧 ！</h2>
          <v-btn
            color="primary"
            class="mb-5"
            @click="start()"
          >
            開始
          </v-btn>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
    <alert-dialog></alert-dialog>
    
  </v-container>
</div>
  
</template>

<script>
  import LoginTitle from '../components/LoginTitle.vue'
  import BotCards from '../components/BotCards.vue'
  import Terms from '../components/Terms.vue'
  import AlertDialog from '../components/AlertDialog.vue'
  import { mapGetters,mapActions,mapMutations } from 'vuex'
  
  export default {
    components: { LoginTitle, BotCards, Terms, AlertDialog },
    data(){
      return{
        hover: false,
        chosenBot: {},
        agree: false,
      }
    },
    computed:{
      ...mapGetters(['step','whitePage','bots','bot'])
    },
    methods:{
      ...mapActions(['getLoginUrl','login','getModuleAuthUrl','chooseBot']),
      ...mapMutations(['setStep']),
      async clickChoose() {
        try {
            if(Object.keys(this.bot).length==0){
              alert('您還尚未選擇帳號哦！')
              return
            }
            let tmp = await this.chooseBot(this.bot.id)
            if(tmp.msg == 'ok'){
              localStorage.setItem('activeBot',JSON.stringify(this.bot))
              this.$store.commit('setStep', 4)
            }else{
              console.log(tmp.msg);
            }
        } catch (error) {
            console.log(error);
            throw new Error('Get bots Fail')
        }
      },
      start(){
        let items = [
          {
            text: '官方帳號',
            disabled: false,
            href: '#',
          },
          {
            text: this.bot.display_name,
          },
          {
            text: '邀請活動',
          },
        ]
        localStorage.setItem('showBots', false)
        localStorage.setItem('invBread',JSON.stringify(items))
        this.$router.push('/invit')
      }
    },
  }
</script>
<style lang="scss" scoped>
  .mgmTitle{
    margin: 8vh auto 3vh;
  }
  .stepContent{
    margin: 60px auto;
    min-height: 30vh;
    text-align: center;
    display: flex;
    justify-content: center;
    &_bot{
      text-align: center;
      padding: 16px;;
    }
    &_text{
      text-align: left;
      p{
        margin-bottom: 0;
        line-height: 32px;
      }
      span{
        color: #74b36b;
      }
    }
  }
</style>
