var host_url = process.env.VUE_APP_HOST_URL;
const state = {
    lineP5: null,
    pointAmnt: [],
};

const getters = {
    lineP5: (state) => state.lineP5,
    pointAmnt: (state) => state.pointAmnt,
    
};

const actions = {
    async getTicketSummary({commit}) {
        try {
            let resp = await axios.get(host_url + "/tickets/summary/");
            console.log('summary',resp.data);
            let data = resp.data
            if(data.length == 0){
                commit('setLineP5', 0)
                commit('setPointAmnt',[])
            }else{
                commit('setLineP5',data[0].amount)
                let d = data.map(el=>{
                    el.price = el.type.slice(11)
                    return el
                })
                console.log(d);
                commit('setPointAmnt', d)
            }
        } catch (error) {
            console.log(error);
            throw new Error('Get bots Fail')
        }
    },

};

const mutations = {
    setLineP5: (state, lineP5) => (state.lineP5 = lineP5),
    setPointAmnt: (state, pointAmnt) => (state.pointAmnt = pointAmnt),
};

export default{
    state,
    getters,
    actions,
    mutations,
}