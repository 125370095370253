<template>
  <div style="position:relative;">
    <v-container class="wrap" >
      <!-- <v-breadcrumbs :items="items" class="pl-0 mt-12">
        <template v-slot:item="{ item }">
          <v-breadcrumbs-item
            :href="item.href"
            :disabled="item.disabled"
            @click="$store.commit('setShowBots',true)"
          >
            <span :class="{'font-weight-bold': showBots}">{{ item.text }}</span>
          </v-breadcrumbs-item>
        </template>
      </v-breadcrumbs> -->
      <!-- <v-btn @click="$store.commit('setAlertDialog',true)">hi</v-btn> -->
      <bot-cards v-show="showBots" class="mt-0"></bot-cards>
      <invit-table v-show="!showBots"></invit-table>
      <invit-create></invit-create>
      <alert-dialog></alert-dialog>
    </v-container>
  </div>
  
</template>

<script>
  import BotCards from '../components/BotCards.vue'
  import InvitTable from '../components/InvitTable.vue'
  import InvitCreate from '../components/InvitCreate.vue'
  import AlertDialog from '../components/AlertDialog.vue'
  import { mapActions, mapGetters } from 'vuex'
  export default {
    components: { BotCards, InvitTable, InvitCreate, AlertDialog},
    data(){
      return{
        items: [{text: '官方帳號', disabled: false, href: '#'}],
      }
    },
    watch:{
      showBots(val){
        console.log('showBot',val);
        if(val){
          console.log('items',this.items);
          this.items = this.items.slice(0,1)
          this.$store.commit('setInvitations' , [])
          this.$store.commit('setBot', {})
        }
        localStorage.setItem('showBots',val)
      },
      async bot(val){
        console.log('bot',val);
        if(Object.keys(val).length != 0){
          this.$store.commit('setIsloading',true)
          let obj = {}
          obj.text = val.display_name
          this.items.splice(1,1,obj)
          this.items.splice(2,1,{text: '邀請活動'})
          localStorage.setItem('activeBot',JSON.stringify(this.bot))
          await this.chooseBot(this.bot.id)
          await this.getInvitationList()

          // set OA's name as user_id to GA
          try {
            // this.$gtag.set({'user_id': this.bot.display_name})
            this.$gtag.config({ user_id: this.bot.display_name })
          } catch (error) {
            console.log(error);
          }
        }
      },
      items(val){
        console.log('item change' , val,this.bot);
        localStorage.setItem('invBread',JSON.stringify(val))
      }
    },
    computed:{
      ...mapGetters(['bot','showBots'])
    },
    mounted(){
      let invBread = JSON.parse(localStorage.getItem('invBread'))
      // let showBots = JSON.parse(localStorage.getItem('showBots'))
      // console.log(!!invBread)
      if(invBread) this.items = invBread
      // if(showBots != null){
      //   console.log('showBots',showBots);
      //   if(showBots){
      //     this.$store.commit('setInvitations' , [])
      //   }
      //   this.$store.commit('setShowBots',showBots)
      // }
    },
    methods:{
      ...mapActions(['chooseBot','getInvitationList']),
    },
  }
</script>

