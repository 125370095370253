import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Landing from '../views/Landing.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Landing',
    component: Landing
  },
  {
    path: '/invit',
    name: 'Home',
    component: Home
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue')
  },
  {
    path: '/bind_oa',
    name: 'BindOA',
    component: () => import('../views/units/BindOA.vue')
  },
  {
    path: '/entrance',
    name: 'Entrance',
    component: () => import('../views/units/Entrance.vue')
  },
  {
    path: '/invitation',
    name: 'Invitation',
    component: () => import('../views/units/Invitation.vue')
  },
  {
    path: '/ticket/:point?',
    name: 'Ticket',
    component: () => import('../views/Ticket.vue')
  },
  {
    path: '/rank',
    name: 'Rank',
    component: () => import('../views/Rank.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

export default router
