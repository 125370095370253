<template>
  <v-container>
    <header>
        <div class="text-center">
        <h1 class="hTitle">MGM<span class="ml-1 primary--text">增粉模組</span></h1>
        <h3 class="subTitle mt-2">快速建立你的好友募集活動</h3>
        </div>
    </header>
  </v-container>
</template>

<script>
  export default {
    name: 'HelloWorld',

    data: () => ({
    })
  }
</script>
<style lang="scss" scoped>
// header{
//     margin: 8vh auto 3vh;
// }
.hTitle{
    letter-spacing: 1px;
    font-size: 48px;
}
.hTitle span{
    color:#555;
    letter-spacing: 2px;
    font-size: 42px;
}
.subTitle{
    letter-spacing: 2px;
    color: #555;
    font-size: 18px;
}
</style>

