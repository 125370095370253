var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{staticClass:"mb-5"},[_c('h1',{staticClass:"nowrap"},[_vm._v("邀請活動")]),_c('p',{staticClass:"ml-1 mb-5 body-2 grey--text"},[_vm._v("透過邀請活動提升官方帳號好友數！")])]),_c('v-col',[_c('div',{staticClass:"text-right mt-5"},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.createInv}},[_c('v-icon',{staticStyle:{"margin-top":"1px"}},[_vm._v("mdi-plus")]),_c('span',[_vm._v("建立新活動")])],1)],1)])],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.invitations,"items-per-page":5,"loading":_vm.isloading},scopedSlots:_vm._u([{key:"item.period",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"green--text text--lighten-1 font-weight-bold"},[_vm._v(" "+_vm._s(_vm._f("moment")(item.start_at))+" ~ "+_vm._s(_vm._f("moment")(item.end_at))+" ")])]}},{key:"item.updated_at",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"color":"#777"}},[_vm._v(" "+_vm._s(_vm._f("moment")(item.updated_at))+" ")])]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"color":"#777"}},[_vm._v(" "+_vm._s(_vm._f("moment")(item.created_at))+" ")])]}},{key:"item.is_active",fn:function(ref){
var item = ref.item;
return [_c('v-switch',{attrs:{"label":item.is_active ? '開啟' : '關閉',"dense":""},on:{"change":function($event){return _vm.toggleStatus(item)}},model:{value:(item.is_active),callback:function ($$v) {_vm.$set(item, "is_active", $$v)},expression:"item.is_active"}})]}},{key:"item.deleted",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[_c('v-btn',{staticClass:"px-5 mr-2",attrs:{"small":"","icon":"","color":"green lighten-2"},on:{"click":function($event){return _vm.edit(item.id)}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1),_c('v-btn',{staticClass:"px-5",attrs:{"small":"","icon":"","color":"grey"},on:{"click":function($event){return _vm.deleteInv(item)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)],1)]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }