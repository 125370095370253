var host_url = process.env.VUE_APP_HOST_URL;
import router from "../../router";
const state = {
    isloading: false,
    bgHeight: '100vh',
    invitations: [],
    invit: {},
    createDialog: false,
    showBots: true,
    editmode: false,
    alertDialog: false,
    alertMsg: {mode:'', msg:''},
    deleteItem: false,
    cancelAns: false,
};

const getters = {
    isloading: (state) => state.isloading,
    bgHeight: (state) => state.bgHeight,
    invitations: (state) => state.invitations,
    invit: (state) => state.invit,
    createDialog: (state) => state.createDialog,
    showBots: (state) => state.showBots,
    editmode: (state) => state.editmode,
    alertDialog: (state) => state.alertDialog,
    alertMsg: (state) => state.alertMsg,
    deleteItem: (state) => state.deleteItem,
    cancelAns:(state) => state.cancelAns,
};

const actions = {
    async getInvitationList({ commit }) {
        try {
            // commit('setInvitations', [])
            commit('setIsloading',true)
            let resp = await axios.get(`${host_url}/invitations/`);
            console.log('invitations',resp.data);
            commit('setInvitations',resp.data);
            localStorage.setItem('invitations', JSON.stringify(resp.data))
        } catch (error) {
            commit('setIsloading',false)
            console.log(error);
            throw new Error('Get bots Fail')
        }
        commit('setIsloading',false)
    },
    async getInvitation({ commit }, id) {
        try {
            let resp = await axios.get(`${host_url}/invitations/${id}/`);
            console.log('invitation',resp.data);
            commit('setInvit', resp.data)
            commit('setEditmode', true)
        } catch (error) {
            console.log(error);
            throw new Error('Get bots Fail')
        }
    },
    async createInvitation({},payload) {
        try {
            console.log(payload);
            let resp = await axios.post(host_url + "/invitations/", payload);
            console.log(resp.data);
        } catch (error) {
            console.log(error);
            throw new Error('Get bots Fail')
        }
    },
    async updateInvitation({}, invit) {
        try {
            let resp = await axios.patch(host_url + `/invitations/${invit.id}/`, invit.payload);
            console.log('update',resp.data);
        } catch (error) {
            console.log(error);
            throw new Error('Get bots Fail')
        }
    },
    async deleteInvitation({state,dispatch}) {
        try {
            let resp = await axios.delete(host_url + `/invitations/${state.deleteItem.id}/`);
            console.log('deleteInvitation', resp.data);
            dispatch('getInvitationList')
        } catch (error) {
            console.log(error);
            throw new Error('delete bots Fail')
        }
    },
    async toggleInvitation({dispatch},toggle) {
        try {
            let resp = await axios.post(host_url + `/invitations/${toggle.id}/${toggle.movement}/`);
            console.log('activateInvitation', resp.data);
        } catch (error) {
            console.log(error);
            throw new Error('activate invitation Fail')
        }
    },
};

const mutations = {
    setIsloading: (state, isloading) => (state.isloading = isloading),
    setBgHeight: (state, bgHeight) => (state.bgHeight = bgHeight),
    setInvitations: (state, invitations) => (state.invitations = invitations),
    setInvit: (state, invit) => (state.invit = invit),
    setCreateDialog: (state, createDialog) => (state.createDialog = createDialog),
    setShowBots: (state, showBots) => (state.showBots = showBots),
    setEditmode: (state, editmode) => (state.editmode = editmode),
    setAlertDialog: (state, alertDialog) => (state.alertDialog = alertDialog),
    setAlertMsg: (state, alertMsg) => (state.alertMsg = alertMsg),
    setDeleteItem: (state, deleteItem) => (state.deleteItem = deleteItem),
    setCancelAns: (state, cancelAns) => (state.cancelAns = cancelAns),
};

export default{
    state,
    getters,
    actions,
    mutations,
}