<template>
    <div v-if="isloading" class="sp-bg" :style="{height:$store.getters.bgHeight}">
        <pacman-loader :loading="isloading" :color="color" :size="size" class="pacman"></pacman-loader>
    </div>
</template>
<script>
import PacmanLoader from 'vue-spinner/src/PacmanLoader.vue'
export default {
    components:{PacmanLoader},
    data(){
        return{
            color:'#85b97d',
            size:'30px'
        }
    },
    computed:{
        isloading(){ return this.$store.getters.isloading}
    },
    mounted(){
    }
}
</script>
<style lang="scss">
  .sp-bg{
    position: absolute;
    top: 0;
    width: 100vw;
    background: rgba(0, 0, 0, 0.6);
    z-index: 98;
  }
  .pacman{
    position: absolute !important;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
  }
</style>