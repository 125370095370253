import Vue from 'vue'
import Vuex from 'vuex'
import login from './modules/login'
import invit from './modules/invit'
import ticket from './modules/ticket'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    login,
    invit,
    ticket,
  }
})
