import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
import VueGtag from "vue-gtag";
import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";

Vue.config.productionTip = false

// import axios from 'axios'
// import VueAxios from 'vue-axios'
// Vue.use(VueAxios, axios)

axios.interceptors.request.use((config) => {
  // Do something before request is sent
  let path = router.history.current.path
  let isLogin = document.cookie.includes('lymn_login')
  if (!isLogin && path != '/login') {
    console.log('turn to login page');
    store.dispatch('clearStorage')
    router.push('/login')
  }
  return config;
}, function (error) {
  // Do something with request error
  console.log('error');
  return Promise.reject(error);
});

Vue.use(VueGtag, {
  config: { id: process.env.VUE_APP_GA_TRACKING_ID }
}, router);

Sentry.init({
  Vue,
  dsn: "https://a1b01f55b9704659be0917ed9c74efb8@o1086150.ingest.sentry.io/6097906",
  integrations: [
    new Integrations.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: ["localhost", "channel-api.lychee.pro", /^\//],
    }),
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
  // tracesSampleRate: 0.25,
});


new Vue({
  components: {
    PulseLoader
  },
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
