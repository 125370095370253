<template>
    <div>
        <v-row align="center">
            <v-col class="mb-5">
                <h1 class="nowrap">邀請活動</h1>
                <p class="ml-1 mb-5 body-2 grey--text">透過邀請活動提升官方帳號好友數！</p>
            </v-col>
            <v-col>
                <div class="text-right mt-5">
                <v-btn @click="createInv" color="primary"><v-icon style="margin-top:1px;">mdi-plus</v-icon>
                <span>建立新活動</span>
                </v-btn>
                </div>
            </v-col>
        </v-row>
        <v-data-table
        :headers="headers"
        :items="invitations"
        :items-per-page="5"
        :loading="isloading"
        class="elevation-1"
        >
            <template v-slot:[`item.period`]="{ item }">
                <span class="green--text text--lighten-1 font-weight-bold">
                    {{ item.start_at | moment }} ~ {{ item.end_at | moment}}
                </span>
            </template>
            <template v-slot:[`item.updated_at`]="{ item }">
                <span style="color:#777">
                {{ item.updated_at | moment }}
                </span> 
            </template>
            <template v-slot:[`item.created_at`]="{ item }">
                <span style="color:#777">
                {{ item.created_at | moment }}
                </span> 
            </template>
            <template v-slot:[`item.is_active`]="{ item }">
                <v-switch
                    v-model="item.is_active"
                    :label="item.is_active ? '開啟' : '關閉'"
                    dense
                    @change="toggleStatus(item)"
                ></v-switch>
            </template>
            <template v-slot:[`item.deleted`]="{item}">
                <div class="d-flex">
                    <v-btn @click="edit(item.id)" small icon color="green lighten-2" class="px-5 mr-2">
                        <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                    <v-btn @click="deleteInv(item)" small icon color="grey" class="px-5">
                        <v-icon>mdi-delete</v-icon>
                    </v-btn>
                </div>
            </template>
        </v-data-table>
    </div>
</template>
<script>
import { mapGetters,mapActions,mapMutations } from 'vuex'
import moment from 'moment'
export default {
    components: { moment },
    data(){
        return{
            headers: [
                {
                    text: '活動名稱',
                    align: 'left',
                    sortable: false,
                    value: 'title',
                    width: 220
                },
                // { text: '活動內容', value: 'desc', width:150 },
                { text: '活動期間', value: 'period',width:200,sortable: false},
                { text: '更新時間', value: 'updated_at',width:200},
                { text: '建立時間', value: 'created_at',width:200},
                { text: '狀態', value: 'is_active', width: 150 },
                { text: '操作', value: 'deleted', width: 150,sortable: false },
            ],
            
        }
    },
    computed:{
        ...mapGetters(['isloading','invitations'])
    },
    filters: {
        moment: function (date) {
            return moment(date).format('YYYY-MM-DD HH:mm:ss');
        }
    },
    mounted(){
        this.getInvitationList()
    },
    methods:{
        ...mapActions(['getInvitationList','getInvitation','toggleInvitation','deleteInvitation']),
        ...mapMutations(['setCreateDialog','setEditmode','setAlertDialog','setAlertMsg','setDeleteItem','setIsloading']),
        moment(date) {
            let a = moment(date).tz("Asia/Taipei");
            return a;
        },
        createInv(){
            this.setCreateDialog(true)
            this.setEditmode(false)
        },
        async edit(id){
            this.setIsloading(true)
            this.setCreateDialog(true)
            await this.getInvitation(id)
            this.setIsloading(false)
        },
        async toggleStatus(item){
            this.setIsloading(true)
            console.log(item);
            let movement = item.is_active ? 'activate' : 'deactivate'
            await this.toggleInvitation({id:item.id, movement})
            await this.getInvitationList()
        },
        async deleteInv(item){
            this.setDeleteItem(item)
            this.setAlertDialog(true)
            let msg = `刪除後無法復原，確定要刪除 "${item.title}" 邀請活動嗎？`
            this.setAlertMsg({mode:'delete',msg})
        },
    }
}
</script>
<style>
.v-data-table__mobile-row__header{
    white-space: nowrap;
}

</style>